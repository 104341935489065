import { setToken, getToken } from "@/utils/auth"
export const user = {
    namespaced: true,
    state: {
        userInfo: {
            uuid: "111",
            nickName: "",
            headerImg: "",
            authority: "",
        },
        token: getToken(),
    },
    mutations: {
        SETUSERINFO(state, userInfo) {
            // 这里的 `state` 对象是模块的局部状态
            state.userInfo = userInfo;
        },
        SETTOKEN(state, token) {
            // 这里的 `state` 对象是模块的局部状态
            state.token = token;
            setToken(token);
        },
    },
    getters: {
        getStateUserInfo(state) {
            return state.userInfo;
        },
        getStateToken(state) {
            return state.token;
        },

    },
    actions: {
        LoginIn({ commit }, loginInfo) {
            commit('SETUSERINFO', loginInfo);
        }
    }
}