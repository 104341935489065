import { createStore } from 'vuex'
import account from './modules/account'
import { user } from './modules/user'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account, user
  }
})
