
// const routeModuleList = {
//     orderNo: 1,
//     path: '',
//     name: 'index',
//     component: () => import('@/views/layouts/baseLayout.vue'),
//     hideChildrenInMenu: true,
//     meta: {
//         icon: 'HomeOutlined',
//         title: '首页',
//     },
//     redirect: '/index',
//     children: [
//         {
//             path: '/index',
//             name: 'index',
//             component: () => import('@/views/index/index.vue'),
//             meta: {}
//         }
//     ]
// }
const page404 = {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/error/404.vue'),
}

export const asyncRoutes = [page404]