import Cookies from "js-cookie";

const TokenKey = "Web-Token";
// 时间
const LoginKey = "hrsass_login_time";
export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    // 设置token  不要 return
    // 记录一下登录时候的时间戳
    Cookies.set(LoginKey, Date.now());
    Cookies.set(TokenKey, token);
    // return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}
// 读取登录的时间
export const getLoginTime = () => {
    return Cookies.get(LoginKey);
};

// 读取登录的时间
export const setLoginTime = () => {
    Cookies.set(LoginKey, Date.now());
};