import {
  createRouter,
  createWebHistory
} from 'vue-router'


const routes = [{
    path: '/tokenlogin',
    name: 'tokenlogin',
    component: () => import('@/views/login/tokenLogin'),
  },
  {
    path: '/',
    name: 'root',
    component: () => import('@/components/layouts/headerLayout.vue'),
    redirect: '/home',
    children: [{
        path: '/home',
        name: 'home',
        // component: () => import('@/views/system/user/index'),
        component: () => import('@/views/index/Home.vue'),
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('@/views/project/index'),
      },
      {
        path: '/project_ch',
        name: 'project_ch',
        component: () => import('@/views/project/list'),
      },
      {
        path: '/wish',
        name: 'wish',
        component: () => import('@/views/wish/index'),
      }
    ]
  },
  {
    path: '/resources-index',
    name: 'resources-index',
    component: () => import('@/components/layouts/sideLayout.vue'),
    redirect: '/resources',
    children: [

      {
        path: '/resources',
        name: 'resources',
        component: () => import('@/views/resources/index'),
      },
      // {
      //   path: '/wish',
      //   name: 'wish',
      //   component: () => import('@/views/wish/index'),
      // }
    ]
  },

  {
    path: '/wish-index',
    name: 'wish-index',
    component: () => import('@/components/layouts/sideLayoutTwo.vue'),
    redirect: '/wish',
    children: [

      // {
      //   path: '/resources',
      //   name: 'resources',
      //   component: () => import('@/views/resources/index'),
      // },
      {
        path: '/wish',
        name: 'wish',
        component: () => import('@/views/wish/index'),
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/layouts/AdminLayout'),
    children: [{
        path: '/admin/user',
        name: 'user',
        component: () => import('@/views/system/user/index'),
      },
      {
        path: '/admin/role',
        name: 'role',
        component: () => import('@/views/system/role/index'),
      }
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router