import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import { asyncRoutes } from './router/asyncRoutes'
import { getToken, getLoginTime, removeToken } from "@/utils/authCookie"

const whiteList = ['tokenlogin', 'resources']
const loginRoutePath = '/tokenlogin'
const defaultRoutePath = '/'
let isNeedGetRoutes = true


// 2 个小时  7200000 ms 就是 时间戳 2 个小时 是 token 过期的时间期限
const tokenLoginTime = 1000 * 60 * 60 * 12;
function checkTimeOut() {
    // 当前时间 发送请求的时间 在请求拦截器调用这个函数 就是发送请求的当前时间
    const curTime = Date.now();
    // 登录时候的时间
    const loginTime = Number(getLoginTime());
    console.log(loginTime);
    //   当前的时间 - 登录时候的时间 如果大于 2 小时 即 7200000 说明 token 过期了
    if (curTime - loginTime > tokenLoginTime) {
        // 过期了
        // setLoginTime();
        return true;
    } else {
        return false; //没有过期
    }
}
console.log(checkTimeOut());
if (checkTimeOut()) {
    removeToken()
}
router.beforeEach((to, from, next) => {
    console.log(to);
    console.log(from);
    NProgress.start();

    let token = getToken();
    console.log(token);
    if (token) {
        if (to.path === loginRoutePath) {
            // next({ path: defaultRoutePath })
            next()
            NProgress.done()
        } else {
            if (isNeedGetRoutes) {
                /**
                 * 获取动态路由列表
                 * 路由权限过滤
                 */
                asyncRoutes.forEach(r => {
                    router.addRoute(r)
                    console.log(11)
                })
                /**
                 * 添加到路由options列表中，如果不执行此步骤会导致动态路由不能在左侧的菜单显示
                 */
                router.options.routes.push(...asyncRoutes)
                isNeedGetRoutes = false
                // 请求带有 redirect 重定向时，登录自动重定向到该地址
                const redirect = decodeURIComponent(from.query.redirect || to.path)
                if (to.path === redirect) {
                    // set the replace: true so the navigation will not leave a history record
                    next({ ...to, replace: true })
                } else {
                    // 跳转到目的路由
                    next({ path: redirect })
                }
            } else {
                next()
                NProgress.done()
            }
        }


    }
    else {
        if (whiteList.indexOf(to.name) > -1) {
            next()
            NProgress.done()
        } else {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
        }

    }

});

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
