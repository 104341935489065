import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/base.css"
import {
    message
} from 'ant-design-vue';
//设置跨系统通过token 登录
import {
    setUrlToken
} from "@/utils/auth"
setUrlToken(router);



// 引入Antd-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './permission' // permission control

// 视频播放

import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

const app = createApp(App)

app.config.globalProperties.$Message = message;
app.use(store)
app.use(router)
app.use(Antd)
app.use(vue3videoPlay)
app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')