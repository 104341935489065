const TokenKey = 'xly-Token'

export function getToken() {
	return localStorage.getItem(TokenKey)
}

export function setToken(token) {
	return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
	return localStorage.removeItem(TokenKey)
}

//设置跨系统通过token 登录
import { getUrlParams } from "@/utils/common"
import router from '@/router'
export function setUrlToken() {
	let paramsObj = getUrlParams(window.location.search);

	if (paramsObj.token && paramsObj.loginType && paramsObj.loginType == 1) {
		setToken(paramsObj.token);
		console.log(getToken());
		if (paramsObj.redirect) {
			console.log(paramsObj.redirect);
			// router.push(`/${sessionStorage.getItem('routeName')}`)
			console.log(router);
			router.push({ path: "/wish-index" })
		}
	}
}



