<template>
  <div id="warp">
    <a-spin :spinning="$store.state.account.loading">
      <router-view />
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      loadingProps: {
        spinning: false,
      },
    };
  },
};
</script>
<style >
</style>