export default {
    state: {
        user: 'test',
        loading: false, //全局加载状态
        uploadList: [], //上传列表
    },
    getters: {
        getUploadList(state) {
            return state.uploadList;
        }
    },
    mutations: {
        SETLOADING(state, load) {
            // 这里的 `state` 对象是模块的局部状态
            state.loading = load;
        },
        SETUPLOADLIST(state, val) {
            state.uploadList.push(val);
        }
    },
    actions: {
        showLoading(context, load) {
            context.commit("SETLOADING", load)
        },
        setUploadList(context, val) {
            context.commit("SETUPLOADLIST", val)
        }
    }
}